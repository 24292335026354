.utility-nav {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    padding: 25px 15px;
    border-top: 1px solid $light;

    .check-out {
        color: #fff;

        @include media-breakpoint-up(lg) {
            color: $link-color;
        }
    }

    a {
        display: block;
        padding: 10px;
        text-decoration: none;
        color: #fff;
        font-weight: bold;
    }

    .icon-cart, .icon-cart-inactive, .icon-contact, .icon-location {
        margin-right: 5px;
    }

    .icon-cart {
        position: relative;

        .quantity {
            position: absolute;
            top: -6px;
            right: -6px;
            height: 16px;
            width: 16px;
            line-height: 16px;
            text-align: center;
            border-radius: 50%;
            background: $link-color;
            color: #fff;
            font-size: 10px;
            font-weight: bold;
        }
    }

    @include media-breakpoint-up(lg) {
        position: relative;
        background-color: #fff;
        padding: 5px 15px;
        justify-content: flex-end;
        margin: 0;
        z-index: 1002;
        a {
            padding: 0 10px;
            color: #000;

            &:hover {
                text-decoration: underline;
                color: $link-color;

                .icon-contact {
                    background-position: -192px 0;
                }

                .icon-location {
                    background-position: -58px 0;
                }

                .icon-newspaper {
                  background-position: -503px 0;
                }

                .icon-cart-inactive {
                    background-position: -310px 0;
                }
            }
        }
    }
}

.js-utility-nav.position-fixed {
    right: 15px;
    top: 0;
    z-index: 99;

    .utility-nav {
        padding: 0 15px;

        li {
            font-size: $font-size-sm;
        }
    }
}