a,
.link-style {
    text-decoration: none;
    color: $primary;
}

.link-style:hover {
    color: #7a1919;
    text-decoration: underline;
}

.bg-primary .featured, .bg-dark .featured {
    color: $white;
    &:hover {
        color: $dark;
    }
}

a.card, a.unlink {
    color: $dark;
    text-decoration: none;
    &:hover {
        color: #000;
        text-decoration: none;
    }
}