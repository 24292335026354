@mixin animate-left {
    opacity: 0;
    transform: translateX(-40px);
    transition: all 0.6s ease-out;
    &.in-view {
        opacity: 1;
        transform: none;
    }
}
@mixin animate-right {
    opacity: 0;
    transform: translateX(40px);
    transition: all 0.6s ease-out;
    &.in-view {
        opacity: 1;
        transform: none;
    }
}

.js-categories {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: .3;
    visibility: visible;
    transition: all 1s ease;
    transition-delay: 2s;
    z-index: 99;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &.in-view:before{
    opacity: 0;
    visibility: hidden;
  }
}

#pinContainer {
    width: 100% !important;
    min-height: 150vh;
    //overflow: hidden;
    @include media-breakpoint-up(lg) {
      min-height: 100%;
    }
    @media (max-height: 650px) and (orientation:landscape) and (max-width: 991px) {
      min-height: 200vh;
    }
}
.panel {
    height: 100%;
    width: 100%;
    position: absolute; 
}

@keyframes fade-in{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

.fade-in {
    opacity: 0;
    &.in-view {
        animation: fade-in ease 2s;
        animation-fill-mode: forwards;
    }
}


@keyframes lock-slide-in-mobile{
    0% {
      //opacity:0;
      left: -100%;
    }
    100% {
      //opacity:1;
      left: 50%;
    }
  }

@keyframes lock-slide-in-desktop{
    0% {
      translate: transform(-100%,0);
    }
    100% {
      translate: transform(0,0);
    }
  }

@mixin lock-slide-in {
      position: relative;
      //opacity: 0;
      &.in-view {
        animation: lock-slide-in-mobile ease .5s;
        animation-fill-mode: forwards;
    }
    @include media-breakpoint-up(lg) {
        &.in-view {
            animation: none;
        }
    }
  }

  @keyframes dropdown-slide-down {
    0% {
      clip: rect(0,100vw,0,0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      clip: rect(0, 100vw, 100vh, 0);
    }
  }

  @keyframes bobbing {
    0% {
      transform: translateY(-6%);
    }
    50% {
      transform: translateY(3%);
    }
    100% {
      transform: translateY(-6%);
    }
  }

  @keyframes bobbing-shadow {
    0% {
      opacity: .5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: .5;
    }
  }