%icon {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center center;
    .btn-primary & {
        width: 29px;
        height: 29px;
    }
}

%icon-sprite {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-image: url(#{$cdnPath}/img/icon-sprite.png);
    background-size: 526px 23px;
}

.icon-play {
    width: 75px;
}

.icon-play-small {
    width: 45px;
}

.icon-download {
    @extend %icon;
    width: 13px;
    height: 14px;
}
   
.icon-service {
    height: 44px;
}

.icon-location {
    @extend %icon-sprite;
    width: 26px;
    height: 26px;
    background-position: 0 0;
    @include media-breakpoint-up(lg) {
        background-position: -28px 0;
    }
}

.icon-newspaper {
  @extend %icon-sprite;
  width: 26px;
  height: 26px;
  background-position: -443px 0;
  @include media-breakpoint-up(lg) {
    background-position: -473px 0;
  }
}

.icon-contact {
    @extend %icon-sprite;
    width: 26px;
    height: 26px;
    background-position: -132px 0;
    @include media-breakpoint-up(lg) {
        background-position: -162px 0;
    }
}

.icon-cart {
    @extend %icon-sprite;
    width: 26px;
    height: 26px;
    background-position: -250px 0;
    @include media-breakpoint-up(lg) {
        background-position: -310px 0;
    }
}

.icon-cart-alt {
    @extend %icon;
    width: 34px;
    height: 100%;
    background-image: url(#{$cdnPath}/img/icon-cart.svg);
}

.icon-cart-inactive {
     @extend %icon-sprite;
     width: 26px;
     height: 26px;
     background-position: -249px 0;
     @include media-breakpoint-up(lg) {
         background-position: -280px 0;
     }
 }

 .icon-external {
    @extend %icon-sprite;
    width: 26px;
    height: 26px;
    background-position: -369px 0;
}

.icon-find {
    @extend %icon;
    width: 34px;
    height: 100%;
    background-image: url(#{$cdnPath}/img/icon-find.svg);
}

.icon-evaluation {
  @extend %icon;
  width: 18px;
  height: 100%;
  background-image: url(#{$cdnPath}/img/icon-evaluation.svg);
  background-size: 100%;
}

.icon-key {
    @extend %icon;
    width: 34px;
    height: 100%;
    background-image: url(#{$cdnPath}/img/icon-key.svg);
}

.icon-newspaper-circle {
  @extend %icon;
  width: 34px;
  height: 100%;
  background-image: url(#{$cdnPath}/img/icon-newspaper-circle.svg);
}

.icon-app {
    @extend %icon;
    width: 34px;
    height: 100%;
    background-image: url(#{$cdnPath}/img/icon-app.svg);
}

.icon-video {
    @extend %icon;
    width: 34px;
    height: 100%;
    background-image: url(#{$cdnPath}/img/icon-video.svg);
}

.icon-award {
    @extend %icon;
    width: 34px;
    height: 100%;
    background-image: url(#{$cdnPath}/img/icon-award.png);
}

.icon-support {
    @extend %icon;
    width: 34px;
    height: 100%;
    background-image: url(#{$cdnPath}/img/icon-support.svg);
}

.icon-manual-red {
    @extend %icon;
    width: 24px;
    height: 100%;
    min-height: 28px;
    background-image: url(#{$cdnPath}/img/icon-user-manual.svg);
}

.icon-video-red {
    @extend %icon;
    width: 30px;
    height: 100%;
    min-height: 30px;
    background-image: url(#{$cdnPath}/img/icon-video-red.svg);
    margin-top: -5px;
}

.icon-email {
    @extend %icon;
    width: 64px;
    background-size: 100%;
    min-height: 64px;
    background-image: url(#{$cdnPath}/img/icon-email.svg);
}

.icon-email-circle {
    @extend %icon;
    width: 75px;
    background-size: 100%;
    min-height: 75px;
    background-image: url(#{$cdnPath}/img/icon-email-circle.png);
}

.icon-lock {
  @extend %icon;
  width: 28px;
  height: 100% !important;
  background-size: 100%;
  margin-left: 5px;
  background-image: url(#{$cdnPath}/img/icon-lock.svg);
}

.loading-icon {
    height: 100vh;
    width: 100%;
    background: rgba(255,255,255,.75);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    color: $primary;

    &.hidden {
        display: none;
    }
}