legend {
    font-size: 1rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.form-control {
    font-weight: normal;
    font-size: .85rem;
    border: 2px solid #000;

    &.is-invalid {
        background-color: theme-color("dangerLight");
        &:focus {
            box-shadow: 4px 4px 8px rgba(0,0,0,0.15);
        }
    }
    &.is-valid:focus {
        box-shadow: 4px 4px 8px rgba(0,0,0,0.15);
    }
    &:focus {
        box-shadow: 4px 4px 8px rgba(0,0,0,0.15);
        border-color: inherit;
    }

    &::placeholder {
        font-style: italic;
        color: #999;
    }
    
}

.form-group {
    display: flex;
    flex-direction: column;
    position: relative;
    label {
        font-family: $headings-font-family;
        font-weight: 600;
        font-size: $font-size-sm;
        margin-bottom: .25rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        // padding-left: $input-btn-padding-x;

        &.text-smaller {
            font-size: .75rem;
        }
    }
}

.form-label {
  font-family: $headings-font-family;
  font-weight: 600;
  font-size: $font-size-sm;
  margin-bottom: .25rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: $input-btn-padding-x;

  &.text-smaller {
    font-size: .75rem;
  }

  &.text-smaller-bold {
    font-size: .75rem;
    font-weight: 800;
  }
}

.custom-select {
    border-width: 2px;
}

.custom-select-alt {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  appearance: none;
}
.custom-control-label::before {
    background-color: #fff;
    border: 1px solid $border-color;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    border: none;
}

.custom-control-input:disabled ~ .custom-control-label::before {
    background-color: $disabled;
    border-color: $lightGray;
}

.custom-file {
    white-space: nowrap;
}

.custom-file-label {
    border: none;
    background: none;
    //left: 190px;
    margin-left: 195px;
    font-family: $headings-font-family;
    font-weight: 600;
    text-transform: uppercase;
    font-size: .75rem;
    @include media-breakpoint-up(lg) {
        margin-left: 215px;
        font-size: .9rem;
    }
}

.custom-file-input {
    cursor: pointer;
}

/* Since this doesn't extend well in the pseudo element from the original buttons */
.custom-file-input:focus + .custom-file-label, .custom-file-input:hover + .custom-file-label {
    &::after {
        background-image: none;
        letter-spacing: 0;
        box-shadow: none;
        background-position: bottom right;
        clip-path: polygon(0 0, 200px 0, 200px 9px, 157px 60px, 155px 60px, 200px 9px, 200px 60px, 0 200px);
        background-color: $primaryAlt;
    }
}

.custom-file-label::after {
    @extend .btn;
    @extend .btn-dark;
    position: absolute;
    color: #fff;
    right: auto;
    top: 0;
    left: -195px;
    width: 190px;
    font-weight: 500;
    z-index: 2;
    text-transform: none;
    @include media-breakpoint-up(lg) {
        left: -215px;
    }
}

.form-error {
    color: #9A0700;
    font-weight: 600;
}