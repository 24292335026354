html, body {
    min-height: 100%;
}

body {
    background-color: #F8F8F8;
    padding: 15px; //for the white border
    overflow-x: hidden;
    padding-top: 90px; //header height
}

.header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1002;
    //display: none;
    @include media-breakpoint-up(lg) {
        //display: block;
    }
    .ui-autocomplete {
        min-width: 230px;
    }
}

@mixin visuallyHidden() {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
}

.hidden {
    display: none;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[list]::-webkit-calendar-picker-indicator {
    display: none;
}

.white-bar-padding {
    @include media-breakpoint-down(md) {
        padding-top: 55px;
    }
}

.white-bar-bg {
    background-image: url(#{$cdnPath}/img/white-bg.png);
    background-repeat: repeat-x;
    overflow: hidden;
}

.white-bar-tall-bg {
    background-image: url(#{$cdnPath}/img/white-bg-tall.png);
    background-repeat: repeat-x;
    overflow: hidden;
}

.sr-only-mobile {
    @include media-breakpoint-down(md) {
        @include sr-only;
    }
}

.sr-only-desktop {
    @include media-breakpoint-up(lg) {
        @include sr-only;
    }
}

.skip-link:focus {
    color: $primary;
    position: relative !important;
    z-index: $zindex-popover;
}

.overflow-hidden {
    overflow: hidden;
}

.transform-none {
    text-transform: none !important;
}

.text-underline {
    text-decoration: underline;
}

/* Goes on the html tag */
.smooth-scroll {
    scroll-behavior: smooth;
}

//for pages that anchor upwards and the sticky nav interferes--create spacing above scroll point
.sticky-nav-scroll {
    @include media-breakpoint-up(lg) {
        //sticky nav height
        scroll-padding-top: 73px;
    }
}

.ratio {
    position: relative;
    width: 100%;
}
.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: "";
}
.ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%;
}

.table-feature-compare {
    width: 100%;
    tr {
        border: 1px solid #C8C8C8;
        border-width: 1px 0;
    }
    tr:nth-child(odd) {
        td, th {
            background-color: #F2F2F2;
        }
    }
    td, th {
        text-align: center;
        width: 33.33%;
        padding: 14px 10px 10px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 21.6px */
        text-transform: uppercase;
    }

}