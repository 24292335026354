.logo {
    width: calc(100% - 85px);
    height: 41px;
    background-color: #fff;
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 1003;
    &:after {
        content: " ";
        background-image: url(#{$cdnPath}/img/logo-angle.svg);
        background-repeat: no-repeat;
        width: 19px;
        height: 80px;
        position: absolute;
        bottom: 0;
        right: -19px;
    }
    @include media-breakpoint-up(lg) {
        width: 200px;
        height: 78px;
        background-position: center center;
    }
}

.logo__img {
    background-image: url(#{$cdnPath}/img/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 114px 27px;
    width: calc(100% - 45px);
    height: 27px;
    position: absolute;
    left: auto;
    top: 0;

    @include media-breakpoint-up(lg) {
        left: 20px;
        top: 20px;
        width: 155px;
        height: 37px;
        background-size: 155px 37px;
    }
}