.floating-button-container {
    position: relative;
    opacity: 0;
}

.floating-button {
    width: 255px;
    position: relative;
    bottom: -625px;
    left: -15px;
    z-index: 1001; //above white border
    box-shadow: 4px 4px 8px 0 rgba(0,0,0,.15);

  @include media-breakpoint-up(sm) {
    bottom: -425px;
  }

  body.safety-opt-in & {
    bottom: -70px;

    @include media-breakpoint-up(sm) {
      bottom: -40px;
    }
  }

  .featured-button {
      padding-left: 20px;
  }

  &.secondary {
    width: 270px;

    @include media-breakpoint-up(sm) {
      bottom: -40px;
    }
  }
}


.floating-button.position-fixed {
    bottom: 40px!important;
    left: 0;
}




