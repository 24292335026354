.ankle {
    position: relative;
    background-color: $dark;
    margin-top: 150px;
    a {
        color: $dark;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 75px;

        a {
            max-width: 170px;
        }
    }
}
.ankle-inner {
    background-color: #fff;
    border: 1px solid $border-color;
    margin-top: -100px;
    padding: 15px 25px;
    @include media-breakpoint-up(xl) {
        position: absolute;
        right: 0;
        width: 65%;
        height: 173px;
        margin-top: -90px;
        padding-top: 40px;
        padding-bottom: 40px;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        &:before {
            content: url("#{$cdnPath}/img/ankle-angle.png");
            display: block;
            position: absolute;
            bottom: 0;
            left: -79px;
            height: 188px;
        }
    }

  @include media-breakpoint-up(xxl) {
    width: 60%;
  }
}

.ankle-inner a {
    color: $dark;
    &:hover {
        color: $primary;
        text-decoration: none;
    }
}

.ankle-icon {
    width: 35px;
    height: 35px;
    display: block;
    margin: auto;
}

.ankle-text {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 14px;
}