.legal {
    color: #000;
    font-size: .9em;
    position: relative;
    padding-right: 83px;
    a {
        color: #000;
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        &:hover {
            text-decoration: underline;
        }
    }
    &:after {
        content: url('#{$cdnPath}/img/legal.png');
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
    }
} 