h1, h2, h3, .h1, .h2, .h3 {
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px; 
}

h4, h5, h6, .h4, .h5, .h6 {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px; 
}

p, li, blockquote {
    font-size: 1rem;
    line-height: 1.8rem;
}

.featured-desktop-heading {
    @include media-breakpoint-up(lg) {
        font-size: $h1-font-size;
        font-weight: 800;
    }
}

.large-text {
    font-size: 1.15rem;
}

.extra-large-text {
    font-size: 1.25rem;
}

.small-text {
    font-size: .9rem;
}

.smaller-text {
    font-size: .8rem;
}

.featured-text {
    font-size: 1.1rem;
}

.featured-font {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.short-lines {
    line-height: 1.375em;
}

.letter-spacing {
    letter-spacing: 2px;
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
}

.letter-spacing-md-0 {
    @include media-breakpoint-down(md) {
        letter-spacing: 0 !important;
    }
}

.header-accent {
    position: relative;
    &:before {
        content: "/";
        font-family: 'Lato', sans-serif;
        display: inline-block;
        position: relative;
        font-size: 1.2rem;
        top: -2px;
        left: -2px;
    }
    [data-animation] & {
        &:before {
            opacity: 0;
        }
    }
}

.font-weight-xbold   { font-weight: $font-weight-xbold !important; }

.line-height-normal {
    line-height: 1.2 !important;
}

.divider-small {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 138px;
        height: 2px;
        bottom: -12px;
        left: 0;
        background-color: #E1261C;

        @include media-breakpoint-up(lg) {
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.divider-left {
    &:after {
        left: 0;
        transform: none;
    }
}

.divider-collapsed {
    &:after {
        bottom: -18px !important;
    }
}