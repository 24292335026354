@charset "UTF-8";

//$cdnPath: ""; This is inserted upon compilation by the gulp file

.redesign {
    //== bootstrap
    @import "bootstrap/functions";
    @import "bootstrap-variables";
    @import "bootstrap/mixins";
    @import "bootstrap/root";
    @import "bootstrap/reboot";
    @import "bootstrap/type";
    @import "bootstrap/images";
    @import "bootstrap/code";
    @import "bootstrap/grid";
    //@import "bootstrap/tables";
    @import "bootstrap/forms";
    @import "bootstrap/buttons";
    @import "bootstrap/transitions";
    @import "bootstrap/dropdown";
    //@import "bootstrap/button-group";
    //@import "bootstrap/input-group";
    //@import "bootstrap/custom-forms";
    @import "bootstrap/nav";
    @import "bootstrap/navbar";
    //@import "bootstrap/card";
    //@import "bootstrap/breadcrumb";
    //@import "bootstrap/pagination";
    //@import "bootstrap/badge";
    //@import "bootstrap/jumbotron";
    //@import "bootstrap/alert";
    //@import "bootstrap/progress";
    //@import "bootstrap/media";
    //@import "bootstrap/list-group";
    //@import "bootstrap/close";
    //@import "bootstrap/modal";
    //@import "bootstrap/tooltip";
    //@import "bootstrap/popover";
    //@import "bootstrap/carousel";
    @import "bootstrap/utilities";
    @import "bootstrap/print";

    //what would usually be a part of the body
    margin: 0; // 1
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    color: $body-color;
    text-align: left; // 3
    background-color: $body-bg; // 2
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @import "base";
    @import "borders";
    @import "buttons";
    @import "dropdowns";
    @import "typography";
    @import "links";
    @import "forms";
    @import "animation";
    @import "icons";

    @import "frame-border";
    @import "angles";
    @import "ghosted";
    @import "components/modal";
    @import "components/find-a-lock";

    @import "components/legal";
    @import "components/footer";
    @import "components/ankle";
    @import "components/floating-button";
    @import "components/modal";
    @import "components/find-a-lock";

    @import "components/logo";
    @import "components/utility-nav";
    @import "components/nav";
    @import "components/search-bar";
    @import "components/cookie-notification";
    @import "components/hubspot-opt-in";

    @import "thirdparty/jqueryui";

    @import "print";

    .nav-item-link {
        @include media-breakpoint-up(lg) {
            &:before {
                bottom: 18px !important;
            }
        }
    }
}

body {
    background-color: #F8F8F8;
    padding: 15px; //for the white border
    overflow-x: hidden;
    padding-top: 96px; //header height
}

@media print {
	//brand all printed pages
    body {
        &:before {
            content: url(#{$cdnPath}/img/logo.png);
        }
    }
}