.ui-autocomplete {
	position: absolute;
	top: 0;
	left: 0;
    cursor: default;
	z-index: $zindex-dropdown;
	max-width: 206px;
}

.ui-menu {
	list-style: none;
	padding: 0;
	margin: 0;
	display: block;
	outline: 0;
}
.ui-menu .ui-menu {
	position: absolute;
}
.ui-menu .ui-menu-item {
	margin: 0;
	cursor: pointer;
	/* support: IE10, see #8844 */
	list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
}
.ui-menu .ui-menu-item-wrapper {
	position: relative;
	padding: 3px 1em 3px .4em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.ui-menu .ui-menu-divider {
	margin: 5px 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	border-width: 1px 0 0 0;
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
	margin: -1px;
	background-color: $dark;
	color: #fff;
}

.ui-widget {
	font-family: Arial,Helvetica,sans-serif;
	font-size: 1em;
}
.ui-widget .ui-widget {
	font-size: 1em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
	font-family: Arial,Helvetica,sans-serif;
	font-size: 1em;
}
.ui-widget.ui-widget-content {
	border: 1px solid $border-color;
}
.ui-widget-content {
	border: 1px solid $border-color;
	background: #ffffff;
	color: $dark;
}
.ui-widget-content a {
	color: $dark;
}
.ui-widget-header {
	border: 1px solid $border-color;
	background: $light;
	color: $dark;
	font-weight: bold;
}
.ui-widget-header a {
	color: $dark;
}
