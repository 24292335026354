.border-light-gray {
  border-color: lighten($border-color, 15%) !important;
}

.border-bottom-desktop {
  @include media-breakpoint-up(lg) {
    border-bottom: 1px solid $border-color;
  }
}

.border-top-desktop {
  @include media-breakpoint-up(lg) {
    border-top: 1px solid $border-color;
  }
}

.border-bottom-mobile {
  @include media-breakpoint-down(md) {
    border-bottom: 1px solid $border-color;
  }
}

.border-md-right {
  @include media-breakpoint-up(md) {
    border-right: $border-width solid $border-color !important;
  }
}

.border-md-left {
  @include media-breakpoint-up(md) {
    border-left: $border-width solid $border-color !important;
  }
}

.border-xxl-right {
  @include media-breakpoint-up(xxl) {
    border-right: $border-width solid $border-color !important;
  }
}

.border-xxl-left {
  @include media-breakpoint-up(xxl) {
    border-left: $border-width solid $border-color !important;
  }
}