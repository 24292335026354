.footer li > a {
    display: inline-block;
    margin: 5px 0;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    &:hover {
        color: #fff;
        text-decoration: underline;
    }
    @include media-breakpoint-up(lg) {
        font-size: .9em;
    }
}

.footer-social {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    &:hover {
        background: #ABABAB;
    }
    img {
        width: 40px;
    }
}

.country-icon {
    width: 20px;
}

.newsletter-signup {
    min-height: 555px;

    @include media-breakpoint-up(sm) {
        min-height: 330px;
    }

    @include media-breakpoint-up(md) {
        min-height: 385px;
    }

    .form-control {
        padding: 0.7rem;
        height: auto;
        line-height: 1.5;
        font-size: .85rem;
        border: 2px solid black;
    }
}