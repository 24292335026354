
.dropdown-angle {
	padding: 0;

	&.show,
	.navbar-toggler.collapsed {
		.arrow {
			transform: rotateZ(180deg);
			transition-duration: .5s;
		}
	}

	.btn-dropdown {
		position: relative;
	    padding: .75em;
	    font-size: 14px;
	    position: relative;
	    background: #FFF;
	    border: 1px solid $border-color;
	    border-right: 0;
	    text-align: left;
		width: calc(100% - 62px);
		border-radius: 0;
		line-height: inherit;
		transition: all 0.5s ease;
		max-width: 285px;
		cursor: pointer;

		&:before {
	    	content: " ";
		    position: absolute;
		    display: block;
		    width: 100%;
		    height: calc(100% + 2px);
		    top: -1px;
		    right: -1px;
		    z-index: -1;
		    border: 1px solid $border-color;
		    background: #FFF;
		    transform-origin: bottom left;
		    transform: skew(-39deg, 0deg);
	    }

		&:after {
			display: none;
		}

		&:focus:not(:focus-visible) {
			outline: none;
		}
		
		&:focus-visible {
			outline: none;
			.arrow {
				outline: 2px solid $featured;
				outline-offset: 5px;
			}
		}

		&:hover {
			&:before {
				box-shadow: none;
			}

			.arrow-wrapper {
				box-shadow: none;

				&:after {
					box-shadow: none;
				}
			}
		}

		.btn-title {
			color: $dark;

			&.value {
				font-style: inherit;
				color: inherit;
			}	
		}

		.arrow-wrapper {
			content: "";
		    position: absolute;
		    top: 0;
		    bottom: 0;
			right: -42px;
		    height: 100%;
		    width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 50px 41px;
			border-color: transparent transparent theme-color("dark") transparent;
			box-shadow: 4px 2px 4px 0 rgba(0,0,0,0.15);

		    &:after {
		    	content: "";
				position: absolute;
			    top: 0;
			    bottom: 0;
				right: -20px;
			    height: 50px;
			    width: 20px;
				background-color: theme-color("dark");
				box-shadow: 4px 2px 4px 0 rgba(0,0,0,0.15);
		    }

		}

	    .arrow {
			background: url('#{$cdnPath}/img/down-arrow.svg') no-repeat;
			color: #fff;
			position: absolute;
			height: 9px;
			width: 25px;
			right: -13px;
			top: 24px;
			z-index: 1;
			transition-duration: .5s;
		}

	}

	.dropdown-menu,
	.navbar-collapse {
		width: 100%;
		max-width: 347px;
		border: 2px solid theme-color("dark");
		border-radius: 0;
		padding: 0;
		margin-top: 6px;

		.dropdown-item {
			padding: 0.85rem 1rem;
			border-bottom: 1px solid theme-color("dark");
			color: theme-color("dark");
			background-color: #F8F8F8;
			cursor: pointer;

			&:focus-visible {
				outline: 2px solid $featured;
			}

			&:last-child {
				border-bottom: 0;
			}

			&:hover,
			&:active {
				box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.15);
				background-color: #EEEEEE;
				color: inherit;
			}

			&.current {
				position: relative;
				background-color: #EEEEEE;
				&:after {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					border-right: 8px solid #FFC300;
				}
			}

			&.inactive {
				background-color: #DDDCDC;
				color: #9E9E9E;
				cursor: not-allowed;
			}
		}
	}
}