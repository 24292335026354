.hs-form-field {
  @extend .form-label;
}
.hs-submit {
  margin-left: 12px;
}
.hs-button {
  @extend .btn, .btn-primary;
}
.hs-input {
  @extend .form-control;

  &::placeholder {
    font-style: italic;
    color: #6c757d;
  }
}
.hs_error_rollup {
  display:none;
}
.hs-error-msgs {
  list-style: none;
  padding-left: 0;
  line-height: .75rem;
  margin-bottom: 0;
}
.hs-error-msg {
  color: #B92626;
  font-weight: 600;
  font-size: 0.65rem;
  line-height: 1.3;
  text-transform: none;
  margin: .4rem 0 0;
}

.hubspot-form-inline {
  width: 100%;

  .hs-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: flex-start;
    }
  }
  .hs-form-field {
    float: none !important;
    width: auto !important;
  }
  fieldset {
    display: flex;
    flex-direction: column;
    flex-basis: 75%;
    gap: 1rem;
    width: 100%;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      max-width: none !important;
      width: auto;
    }
  }
  .hs-email {
    flex: 4 0 auto;
  }
  .actions {
    margin-top: 29px;
  }

  .submitted-message {
    text-align: center;
    padding-bottom: 40px;
  }
}