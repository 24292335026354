.main-nav {
    display: none;
    position: absolute;
    top: 0;
    width: 100vw;
    height: calc(100vh - 120px);
    background-color: $dark;
    padding-top: 110px;
    z-index: 1001;
    overflow: auto;
    &.show {
        display: block;
    }
    @include media-breakpoint-up(lg) {
        display: block;
        left: 0;
        top: 0;
        overflow: visible;
        //padding-left: 200px; //logo
        //padding-top: 32px; //utility nav and site border
        background: transparent;
        width: 100%;
        height: auto !important; //override bootstrap animation
        padding-top: 40px;
        &.show {
            position: absolute;
        }
    }

    .nav {
        flex-direction: column;
        width: 100%;
        max-width: 576px;
        margin: 0 auto;
        padding: 0 15px;
        transition: background .5s;
        flex-wrap: nowrap;
        overflow: scroll;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            max-width: none;
            overflow: visible;
            height: 60px;
            background: #fff;
            padding-left: 235px;
        }
    }
    .nav-item {
        width: 100%;
        min-height: 60px;
        justify-content: space-between;
        flex-wrap: wrap;
        text-transform: uppercase;
        position: static;

        @include media-breakpoint-up(lg) {
          margin: 5px 0 26px;
          min-height: 0;
        }

        .nav-item-link {
            position: relative;
            display: block;
            color: #fff;
            margin: 5px 0;
            padding: 10px 30px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            width: 100%;
            border-top: 1px solid $light;
            border-bottom: 1px solid $light;

            @include media-breakpoint-up(lg) {
                border: none;
                color: $dark;
                padding: 12px 30px 19px 30px;
                margin: 0;
                line-height: 1;

                &:before {
                    content: "/";
                    font-family: 'Lato', sans-serif;
                    display: inline-block;
                    position: absolute;
                    font-size: 1.2rem;
                    top: 10px;
                    left: 18px;
                    opacity: 0;
                }
                &:hover, &:focus, &.is-active {
                    &:before {
                        opacity: 1;
                    }
                }
                &:hover {
                    color: $primary;
                    text-decoration: none;
                    //padding: 12px 30px 20px 30px;
                }

                .dropdown-is-open & {
                  padding: 12px 30px 20px 30px;
                  &:before {
                    top: 10px;
                  }
                }
                &:focus {
                    outline: none;
                }
                &.is-active {
                    color: $primary;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            width: auto;
            left: 220px; //logo
        }
    }

    .nav-item-dropdown {
        position: absolute;
        top: 7px;
        right: 0;
        z-index: 2;
        border: none;
        background: $dark;
        border-left: 30px solid $dark;
        &:focus {
            background-color: $gray;
            outline: none;
        }
        &:after {
            content: url(#{$cdnPath}/img/down-arrow.svg);
            display: inline-block;
            margin-top: 2px;
        }
        @include media-breakpoint-up(lg) {
            &:not(:focus) {
                @include visuallyHidden();
            }
            &:focus {
                background-color: $primary;
                padding: 0;
                border: none;
            }
        }
    }

    .dropdown-menu {
        background-color: $dark;
        width: 98vw;
        height: 700px;
        margin: 0;
        z-index: 10;
        overflow: auto;
        transform: none !important;
        //position: fixed !important;
        list-style-type: none;
        @include media-breakpoint-up(xl) {
            height: 650px;
        }
        @include media-breakpoint-up(xxl) {
            height: 550px;
        }
        @include media-breakpoint-down(md) {
            position: static !important;
            width: 100%;
            height: auto;
            overflow: visible;
        }
        .main-nav-link {
            display: inline-block;
            text-transform: none;
            padding-top: 15px;
            color: #fff;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            letter-spacing: 1.7px;
        }
        .featured-button {
            //can't let these wrap to two lines when the dropdown isn't wide enough
            @media screen and (min-width: 991px) and (max-width: 1170px) {
                font-size: .77rem;
                width: 250px;
            }
        }
        &.show {
            background-position: 100% 40px;
            background-repeat: no-repeat;
            @include media-breakpoint-up(lg) {
                background-position: top right, bottom left;
                background-size: contain, auto;
                background-repeat: no-repeat;
                display: flex;
                left: .5vw !important;
                animation: dropdown-slide-down ease .5s forwards;
                top: auto !important;
                box-shadow: -8px 6px 5px 0 rgba(0,0,0,.33);
            }
        }
    }
}

//shadow that only appears on legacy pages
.legacy-nav .search-bar {
    box-shadow: 0 2px 6px 3px rgba(0,0,0,0.1);
    @include media-breakpoint-up(lg) {
        box-shadow: none;
    }
}
.legacy-nav .main-nav {
    @include media-breakpoint-up(lg) {
        box-shadow: 0 2px 6px 3px rgba(0,0,0,0.1);

      .nav-item {
        margin-bottom: 0;
        min-height: 55px;
      }
    }
}

#business .dropdown-menu.show {
    background-image: url(#{$cdnPath}/img/nav-mobile-business.png);
    @include media-breakpoint-up(lg) {
        background-image: url(#{$cdnPath}/img/nav-bg-business.jpg), url(#{$cdnPath}/img/nav-ghosted-business-55.png);
    }
}

#personal .dropdown-menu.show {
    background-image: url(#{$cdnPath}/img/nav-mobile-products.png);
    @include media-breakpoint-up(lg) {
        background-image: url(#{$cdnPath}/img/nav-bg-products.jpg), url(#{$cdnPath}/img/nav-ghosted-products-55.png);
    }
}

#support .dropdown-menu.show {
    background-image: url(#{$cdnPath}/img/nav-mobile-support.png);
    @include media-breakpoint-up(lg) {
        //background-image: url(#{$cdnPath}/img/nav-bg-support.jpg), url(#{$cdnPath}/img/nav-ghosted-support-55.png);
        background-image: url(#{$cdnPath}/img/nav-bg-support.jpg);
    }
}

.main-nav .show .nav-item-dropdown:after {
    transform: rotate(180deg);
}

.main-nav__subtitle {
    color: rgba(255,255,255,.6);
    font-size: 1rem;
    text-transform: uppercase;
}

.main-nav-toggle {
    border: none;
    background: url(#{$cdnPath}/img/icon-open.png) no-repeat center center;
    background-repeat: no-repeat;
    width: 45px;
    height: 41px;
    background-position: center center;
    border-radius: 50%;
    transition: none;
    &:focus {
        outline: none;
        background: $light url(#{$cdnPath}/img/icon-open.png) no-repeat center center;
    }
    &[aria-expanded=true] {
        background: url(#{$cdnPath}/img/icon-close.png) no-repeat center center;
        border: none;
    }
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.main-nav__subnav {
    padding-left: 0;
    list-style-type: none;
    animation: fade-in ease .5s forwards;
}

.js-main-nav-container.position-fixed {
    top: 0;
    width: 100%;
    .logo {
        @include media-breakpoint-up(lg) {
            top: 0;
            height: 69px;
        }
    }
    .main-nav {
        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }
    }
    .main-nav .nav-item-link {
        @include media-breakpoint-up(lg) {
            padding-top: 30px;
            padding-bottom: 9px;
            color: $dark;
            &:before {
                bottom: 8px;
                top: unset;
            }
        }
    }
    .main-nav .nav {
        @include media-breakpoint-up(lg) {
            background-color: #fff;
        }
        width: 100%;
    }
    .dropdown-menu.show {
        @include media-breakpoint-up(lg) {
            //top: 70px !important;
        }
    }
}