.ghosted {
    font-family: 'Montserrat', sans-serif;
    font-size: 100px;
    line-height: 100px;
    font-size: calc(100px + (150 - 100) * ((100vw - 320px) / (991 - 320)));
    line-height: calc(100px + (150 - 100) * ((100vw - 320px)/(991 - 320)));
    color: #363636;
    opacity: .035;
    position: absolute;
    font-weight: 800;
    text-transform: uppercase;
    .bg-primary & {
        color: #AC201F;
        opacity: 1;
        text-align: right;
        right: 15px;
        bottom: 0;
    }
    @include media-breakpoint-up(lg) {
        //https://css-tricks.com/snippets/css/fluid-typography/
        font-size: calc(180px + (240 - 150) * ((100vw - 992px) / (1600 - 992)));
        line-height: calc(180px + (190 - 150) * ((100vw - 992px)/(1600 - 992)));
        text-align: left;
        right: auto;
        bottom: auto;
    }
    &.ghosted-sm {
        font-size: 65px;
        font-size: calc(65px + (150 - 100) * ((100vw - 320px) / (991 - 320)));
        line-height: calc(65px + (150 - 100) * ((100vw - 320px)/(991 - 320)));

        @include media-breakpoint-up(lg) {
            font-size: 130px;
            font-size: calc(130px + (240 - 150) * ((100vw - 992px) / (1600 - 992)));
            line-height: calc(130px + (190 - 150) * ((100vw - 992px)/(1600 - 992)));
        }
    }

    &.ghosted-xs {
        font-size: 40px;
        font-size: calc(40px + (150 - 100) * ((100vw - 320px) / (991 - 320)));
        line-height: calc(40px + (150 - 100) * ((100vw - 320px)/(991 - 320)));

        @include media-breakpoint-up(lg) {
            font-size: 70px;
            font-size: calc(70px + (240 - 150) * ((100vw - 992px) / (1600 - 992)));
            line-height: calc(70px + (190 - 150) * ((100vw - 992px)/(1600 - 992)));
        }

        @include media-breakpoint-up(xxl) {
            font-size: 60px;
            font-size: calc(60px + (240 - 150) * ((100vw - 992px) / (1600 - 992)));
            line-height: calc(60px + (190 - 150) * ((100vw - 992px)/(1600 - 992)));
        }

        @media only screen and (min-width: 2000px) {
            font-size: 40px;
            font-size: calc(40px + (240 - 150) * ((100vw - 992px) / (1600 - 992)));
            line-height: calc(40px + (190 - 150) * ((100vw - 992px)/(1600 - 992)));
        }
    }
}

.ghosted--top {
    top: 0;
}

.ghosted--middle {
    top: 50%;
    transform: translate(0,-50%);
}

.ghosted--bottom {
    bottom: 0;
}

.ghosted-video {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    opacity: .1;
    font-size: 170px;
    line-height: 191px;
    position: absolute;
    font-weight: 800;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
}