/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 * 
 * This file contains only the print styles from the HTML5 Boilerplate
 * project. See <https://github.com/h5bp/html5-boilerplate> for more
 * information on the main project.
 */

/* ==========================================================================
   Print styles.
   ========================================================================== */

   @media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important; /* Black prints faster:
                                   http://www.sanbeiji.com/archives/953 */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href^="http"]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

@media print {

    @page { margin: 1cm; }
    
    //brand all printed pages
    body {
        &:before {
            content: url(#{$cdnPath}/img/logo.png);
        }
    }

	* {
		background: transparent !important;
		color: #000 !important;
		text-shadow: none !important;
	}

    nav,
    nav *,
    footer,
    header,
	form:not(#productlist),
	button,
	embed,
	object,
	video,
    .btn,
    .spacer,
    dialog
     { display: none !important; }

    #pinContainerContainer, 
    .ankle, 
    .legal,
    .toggle-button-container,
    .floating-button-container,
    .ghosted
     {
        display: none !important;
    }

    .product-category {
        height: auto;
        position: static !important;
        page-break-inside: avoid;
    }

    .promo, 
    .promo-featured,
    .content,
    .card,
    .product-card {
        display: block !important;
        position: relative !important;
        page-break-inside: avoid !important;
    }

    ul {
        page-break-inside: avoid;
    }

    //remove animation of product category products
    .product-category .col-3 {
        left: 0 !important;
    }

    [data-animate=promo] {
        opacity: 1 !important;
    }

    #heroLanding {
        margin-top: 0;
        opacity: 1 !important;
        position: relative !important;
        display: block !important;
    }

    //lock in view
    .js-hero-landing-lock {
        left: 50% !important;
    }

    //targeting and showing split text fade in
    .js-hero-landing-text div[style] {
        opacity: 1 !important;
    }

    .collapse {
        display: block !important;
    }

    .card-flag svg {
        display: none;
    }

    #productlist .row, 
    #productlist .d-flex,
    #related-products-section .row {
        display: block !important;
    }

    .product-detail-thumb {
        width: auto;
    }
}