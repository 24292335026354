dialog {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: block;
  padding: 0;
  top: 0;
  left: 0;
  background: none;
  border: none;
  max-width: none;
  &.position-fixed {
    position: fixed;
  }
}

dialog:not([open]) {
  display: none;
}

dialog::backdrop { /* native */
  background-color: rgba(255, 255, 255, .92);
}

dialog + .backdrop { /* polyfill */
  background-color: rgba(255, 255, 255, .92);
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
}

._dialog_overlay {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
}

.modal {
  .modal-content {

  }

  .modal-body {
    position: absolute;
    width: 100%;
    top: 0;
    margin: 0;
    padding: 0 !important;

    @include media-breakpoint-up(md) {
      position: absolute;
      width: 100%;
      //top: 10%;
      //height: auto;
    }
  }

  .close {
    position: absolute;
    right: 25px;
    top: 10px;
    border: none;
    background:rgba(255,255,255,.7);
    line-height: 1;
    z-index: 2000;
    padding: 10px;
    border-radius: 50%;

    &:focus {
      outline: none;
    }

    svg {
      width: 25px;
      height: 25px;
    }

    #icon-close g {
      stroke: $dark;
      transition: color 400ms ease-in;
    }

    &:hover {
      cursor: pointer;

      #icon-close g {
        stroke: lighten($dark, 40%);
      }
    }

    @include media-breakpoint-up(md) {
      right: 60px;
      top: 30px;

      .close svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}


// animation
.modal-zoom-out {

  /* start state */
  .modal-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
  }

  &.modal-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  /* animate in */
  &.modal-ready {
    .modal-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.modal-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.modal-removing {

    .modal-with-anim {
      transform: scale(1.3);
      opacity: 0;
    }
    &.modal-bg {
      opacity: 0;
    }

  }

}