.search-bar {
    //display: none;
    position: absolute;
    top: 0px;
    background-color: $dark;
    height: 55px;
    color: #fff;
    width: 100%;
    z-index: 1; 
    padding-left: 15px;
    padding-right: 15px;
    z-index: 1002;
    @include media-breakpoint-up(lg) {
        position: absolute;
        background: transparent;
        width: auto;
        right: 1vw;
        top: 40px;
        color: #000;
    }
    @include media-breakpoint-up(xxl) {
        left: 795px;
    }
}

.search-bar__inner {
    margin-top: 55px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    background: $dark;
    width: 100%;
    @include media-breakpoint-up(lg) {
        margin-top: 0;
        padding-top: 5px;
        background: transparent;
        width: 180px;
        &:after {
            content: " ";
            position: absolute;
            right: 26px;
            bottom: 10px;
            height: 0;
            width: 0;
            border: 5px solid transparent;
            border-left-width: 3px;
            border-right-width: 3px;
            border-bottom-color: #000;
        }

        &.focus:after {
            border-bottom-color: $featured;
            bottom: 11px;
        }
    }
    @include media-breakpoint-up(xl) {
        width: 230px;
    }
}

.search-bar__button {
    position: absolute;
    right: 16px;
    top: 13px;
    border: none;
    background: none;
    width: 55px;
    height: 44px;
    text-align: center;
    &:focus {
        outline: none;
        color: $featured !important;
    }
    &[aria-expanded=false]:focus:not(:focus-visible) {
        color: #fff !important;
    }
    &:focus-visible {
        color: $featured !important;
    }
    svg {
        width: 25px;
        height: 25px;
        margin-top: 5px;
        margin-left: 11px;
    }
    @include media-breakpoint-up(lg) {
        position: static;
        display: none;
    }
}

.site-search__submit {
    display: none;
    border: none;
    background: none;
    @include media-breakpoint-up(lg) {
        display: block;
        position: absolute;
        right: 10px;
        bottom: 4px;
        color: #000;
        cursor: pointer;
        &:focus {
            color: $featured;
            outline: none;
        }
    }
}

.site-search__label {
    position: absolute;
    display: none;
    bottom: 10px;
    left: 15px;
    font-size: .9rem;
    @include media-breakpoint-up(lg) {
        font-style: italic;
        width: 100%;
    }
}

.site-search__input {
    display: block;
    background-color: transparent;
    color: #fff;
    border: none;
    width: 97%;
    margin: 0 auto;
    padding-left: 12px;
    padding-right: 44px;
    font-size: .9rem;
    border-bottom: 1px solid #fff;
    &:focus {
        outline: none;
        border-bottom: 2px solid $featured;
    }
    &:placeholder-shown ~ .site-search__label {
        display: block;
    }
    &:placeholder-shown:focus ~ .site-search__label {
        display: none;
    }
    @include media-breakpoint-up(lg) {
        color: #000;
        border-bottom-color: #000;
    }
}
.js-main-nav-container.position-fixed {

    @include media-breakpoint-up(lg) {
        .search-bar {
            top: 25px;
        }

         .search-bar, .site-search__submit, .site-search__input, .search-bar__inner:after {
             color: $dark;
             border-bottom-color: $dark;
         }

         .site-search__input {
             &:focus {
                 border-bottom-color: $featured;
             }
         }
     }
}

.search-faq-category-result {
    a {
        p {
            margin-bottom: 7px;
            margin-top: 14px;
        }
    }

    p {
        line-height: 1.2rem;
    }
}