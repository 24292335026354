.btn {
	display: inline-block;
    border-radius: 0;
	transition: all 0.15s ease;
	border-width: 1.5px;
	padding: 0.6875rem 2rem .6875rem 2rem;
	cursor: pointer;
	text-decoration: none;
	font-size: .875rem;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	line-height: 22px; /* 157.143% */
	letter-spacing: 0.72px;
    width: fit-content;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-outline-primary {
	color: $dark;
}

.btn-dark,
.btn-outline-dark {
	&:hover, &:focus {
		background-color: $primary;
	}
}

.btn-secondary {
	&:hover, &:focus {
		background-color: $secondaryAlt;
	}
}

.btn-secondary-light {
	background-color: theme-color("secondary-light");

  &:hover,
  &:focus {
    background-color: $secondaryLight;
  }
}

.btn-tertiary {
  border-bottom: 3px solid $featured;
  color: $dark;
  position: relative;
  z-index: 1;
  padding: 0;
  &:hover {
    color: $dark;
  }
  &:after {
    content: " ";
    background-color: $featured;
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  &:hover:after,
  &:focus:after {
    height: 100%;
    transition: height 0.25s;
  }
}

.btn-tertiary-icon {
  color: $dark;
  background-color: transparent;

  .btn-text {
    border-bottom: 3px solid $featured;
    position: relative;
    z-index: 1;

    &:after {
      content: " ";
      background-color: $featured;
      position: absolute;
      width: 100%;
      height: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }

  &:hover,
  &:focus {
    .btn-text:after {
      height: 100%;
      transition: height 0.25s;
    }
  }

  &.disabled,
  &:disabled {
    color: $lightGray;

    .btn-text {
      border: 0;

			&:after {
				display: none;
			}
		}
	}
}

.featured-button { 
	display: block;
	position: relative;
	padding: 0 5px;
	height: 44px;
	line-height: 38px;
	font-size: .8rem;
	text-align: left;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	background: transparent;
	text-transform: uppercase;
	border-top: 1px solid $light;
	border-bottom: 1px solid $light;
	border-left: 1px solid $light;
	border-right: 0;
	color: #fff;
	@media screen and (min-width: 375px) {
		font-size: .8rem;
	}
	&:after {
		position: absolute;
		content: " ";
		top: -1px;
		bottom: 0;
		right: -17px;
		width: 21px;
		height: 44px;
		background-image: url('#{$cdnPath}/img/button-sprite.png');
		background-position: -1px 0;
	}
	&.primary {
		background: $primary;
		border-color: $primary;
		&:after {
			background-image: url('#{$cdnPath}/img/button-sprite.png');
			background-position: -30px 0;
		}
	}
	&.primary-inverse {
		background: transparent;
		border-color: $primary;
		color: $primary;
		&:after {
			background-image: url('#{$cdnPath}/img/button-sprite.png');
			background-position: -119px 0;
		}
	}
    &.secondary {
      background: $secondary;
      border-color: $secondary;
      &:after {
        display: none;
      }
    }
  }


.featured-button {
  display: block;
  position: relative;
  padding: 0 5px;
  height: 44px;
  line-height: 38px;
  font-size: 0.8rem;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  background: transparent;
  text-transform: uppercase;
  border-top: 1px solid $light;
  border-bottom: 1px solid $light;
  border-left: 1px solid $light;
  border-right: 0;
  color: #fff;
  @media screen and (min-width: 375px) {
    font-size: 0.9rem;
  }
  &:after {
    position: absolute;
    content: " ";
    top: -1px;
    bottom: 0;
    right: -17px;
    width: 21px;
    height: 44px;
    background-image: url("#{$cdnPath}/img/button-sprite.png");
    background-position: -1px 0;
  }
  &.primary {
    background: $primary;
    border-color: $primary;
    &:after {
      background-image: url("#{$cdnPath}/img/button-sprite.png");
      background-position: -30px 0;
    }
  }
  &.primary-inverse {
    background: transparent;
    border-color: $primary;
    color: $primary;
    &:after {
      background-image: url("#{$cdnPath}/img/button-sprite.png");
      background-position: -119px 0;
    }
  }
  &.secondary {
    background: $secondary;
    border-color: $secondary;
    &:after {
      background-image: url("#{$cdnPath}/img/button-secondary-sprite.png");
      background-position: 0 0;
    }
  }

  span {
    height: 37px;
    vertical-align: middle;
  }
  &.mobile-xl {
    @include media-breakpoint-down(xs) {
      display: flex;
      align-items: center;
      span {
        line-height: normal;
        font-size: 0.9rem;
      }
    }
  }
}

a.featured-button,
button.featured-button {
  &:hover,
  &:focus {
    cursor: pointer;
    outline: none;
    background-color: $lightGray;
    color: #fff;
    text-decoration: none;
    &:after {
      background-image: url("#{$cdnPath}/img/button-sprite.png");
      background-position: -91px 0;
    }
  }
}

a.featured-button.primary,
button.featured-button.primary {
  &:hover,
  &:focus {
    background: $primaryAlt;
    border-color: $primaryAlt;
    &:after {
      background-image: url("#{$cdnPath}/img/button-sprite.png");
      background-position: -60px 0;
    }
  }
}

a.featured-button.secondary,
button.featured-button.secondary {
  &:hover,
  &:focus {
    background: $secondaryAlt;
    border-color: $secondaryAlt;
    &:after {
      background-image: url("#{$cdnPath}/img/button-secondary-sprite.png");
      background-position: -30px 0;
    }
  }
}

a.featured-button.primary-inverse,
button.featured-button.primary-inverse {
  &:hover,
  &:focus {
    background: $primaryAlt;
    border-color: $primaryAlt;
    color: #fff;
    &:after {
      background-image: url("#{$cdnPath}/img/button-sprite.png");
      background-position: -57px 0;
    }
  }
}

//deprecated, don't use on new development post 2024
.button-link {
  border: none;
  background: none;
  color: $primary;
  text-align: left;
  padding: 0;
  margin: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
    text-decoration: underline;
  }
}

.btn-pager {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 0.85rem;
  line-height: 1;
  vertical-align: center;

  &:hover,
  &:focus {
    background: $featured;
  }

  &.active {
    background: $dark;
    color: #fff;
  }

  &.active:focus {
    color: $featured;
  }
}

.btn-first,
.btn-last {
  font-size: 1.5rem;
  color: $dark;
  text-decoration-color: $featured;

  &:hover {
    color: $dark;
    text-decoration: underline;
    text-decoration-color: $featured;
  }

  &:disabled {
    visibility: hidden;
  }
}

// pdp quantity buttons and some other stuff
.btn-white {
  background-color: #fff;
  color: #000;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    color: #000;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  }
  &:active,
  &:focus {
    box-shadow: none;
    color: $primary;
  }
  &.current {
    border-bottom: 3px solid $featured;
    outline: 1px solid $border-color;
  }
  &.disabled,
  &.inactive {
    background-color: #e5e5e5;
    box-shadow: none;
    cursor: default;
  }

  &.btn-white-secondary {
    border: 1px solid $primary;
    padding: 0.75rem 3.5rem;

    &:hover,
    &:active,
    &:focus {
      background: $primary;
      color: $white;
    }
  }
}

// only used on loto contact form, and is currently not blue
.btn-blue {
  position: relative;
  background-color: #fff;
  color: $dark;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 0.85em;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.09em;
  outline: 0.5px solid $border-color;
  transition: background-color 0.15s ease;

  &:after {
    content: "";
    width: 3px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-color: transparent;
  }

  &:hover {
    color: $dark;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  }
  &:active,
  &:focus-visible,
  &:focus {
    box-shadow: none;
  }
  &.current {
    background: #e7f4f9;
    font-weight: 600;
    box-shadow: none;
    padding: 0.45rem 1rem;

    &:after {
      background-color: #2f7894;
    }
  }
  &.disabled,
  &.inactive {
    background-color: #e5e5e5;
    box-shadow: none;
    cursor: default;
  }
}

//For situations where the layout calls for a button but the content has nothing to link to
.btn-placeholder {
  display: inline-block;
  width: 190px;
  height: 46px;
}

//checkbox buttons
.btn.btn-checkbox {
  position: relative;
  border: 2px solid #979797;
  //padding: .45rem 1.5rem;
  line-height: 1.3;

  &:after {
    content: url("#{$cdnPath}/img/angle-gray-sm.png");
    width: 26px;
    height: 26px;
    position: absolute;
    bottom: -3px;
    right: -3px;
  }

  &.alt-gray:after {
    content: url("#{$cdnPath}/img/angle-gray-gray-sm.png");
  }
}

.btn.btn-checkbox.focus {
  border-color: #525252;
  box-shadow: none;

  &:after {
    content: url("#{$cdnPath}/img/angle-dark-sm.png");
  }
}

.btn.btn-checkbox.focus {
  border-color: #979797;
  box-shadow: none;

  &:after {
    content: url("#{$cdnPath}/img/angle-gray-sm.png");
    width: 26px;
    height: 26px;
    position: absolute;
    bottom: -3px;
    right: -3px;
  }

  &.alt-gray:after {
    content: url("#{$cdnPath}/img/angle-gray-gray-sm.png");
  }
}

.btn.btn-checkbox.active {
  border-color: #2f7894;
  background-color: #e7f4f9;
  box-shadow: none;

  &:after {
    content: url("#{$cdnPath}/img/angle-blue.png");
    position: absolute;
    bottom: -2px;
    right: -2px;
  }
  &.alt-gray:after {
    content: url("#{$cdnPath}/img/angle-blue-gray.png");
  }
}

.back-to-top {
  display: none;
  position: fixed;
  bottom: 40px;
  right: 30px;
  background-image: url("#{$cdnPath}/img/button-back-to-top.png");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 54px;
  height: 50px;
  z-index: 99;

  &.show {
    display: block;
  }

  &:active,
  &:focus {
    background-image: url("#{$cdnPath}/img/button-back-to-top.png") !important;
  }
  &:hover {
    opacity: 0.8;
  }
}

// price spider overrides
.not-primary-action .ps-widget.btn.btn-primary {
	@extend .btn, .btn-outline-primary;
}
#where-to-buy-button {
	@extend .btn-primary;
}
.btn-primary.ps-enabled {
	display: inline-block !important;
}