.find-lock {
  width: 100vw;
  height: calc(100vh - 115px); // Thanks, iOS!
  padding-top: 6vh;
  display: flex;
  flex-flow: column nowrap;

  > * {
    flex: 0;
  }

  > form {
    flex: 1;
  }

  input[type="radio"] {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
  }

  @include media-breakpoint-up(md) {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    display: block;
    padding-top: 2vh;
  }
}

.find-lock-user-answers {
  padding: 0 8vw;
  color: $dark;
  opacity: 1;
  transition: opacity 400ms ease-out;
  min-height: 28px;

  &.active {
    opacity: 1;
  }

  .list-inline {
    text-align: center;
  }

  .list-inline-item {
    font-size: 11px;
    opacity: 0;
    transition: opacity 400ms ease-out;
    text-transform: capitalize;

    &.active {
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }

  .list-inline-item + .list-inline-item {
    &:before {
      content: '/ ';
      margin-left: 4px;
    }
  }

  .list-inline-item:not(:last-child) {
    margin: 0;
  }

  @include media-breakpoint-up(md) {
    min-height: 30px;
  }
}

.find-lock-answers {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  padding: 15px 0 0;

  @include media-breakpoint-up(md) {
    flex-flow: row nowrap;
    justify-content: center;
  }
}

$selectedPadding: 12px;
.find-lock-answer {
  flex: 1 1 auto;
  position: relative;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  transition: all 250ms ease-out;

  &.disabled {
    cursor: not-allowed;
    box-shadow: none;

    &:after {
      content: '';
      position: absolute;
      display: block;
      z-index: 10000;
      background-color: rgba(255,255,255,.7);
      opacity: 1;
      width: 100%;
      height: 101%;
      border: 0;
    }

    .find-lock-label-container {
      cursor: not-allowed;
    }

    .find-lock-image {
        filter: grayscale(100%) contrast(50%);
    }
  }

  &.disabled:hover {
    cursor: not-allowed;
    box-shadow: none;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.6);
  }

  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 1.5vw);
    height: calc(100% + 1.5vw);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //border: 2px solid theme-color('lightGray');
    border: 2px solid $featured;
    opacity: 0;
    transition: opacity 250ms ease-out;
  }

  &.selected {

    .active &:after {
      opacity: 1;
    }

    // Keeping these just in case I need to revert
    //.find-lock-label-container {
    //  width: calc(100% - #{$selectedPadding});
    //}
    //
    //.find-lock-image {
    //  width: calc(100% - #{$selectedPadding});
    //  height: calc(100% - #{$selectedPadding});
    //}
    //
    //.find-lock-label {
    //  width: calc(100% - #{$selectedPadding});
    //
    //  &:after {
    //    //width: calc(100% - #{$selectedPadding});
    //    height: calc(50% - #{$selectedPadding});
    //    bottom: $selectedPadding / 2;
    //    //left: $selectedPadding / 2;
    //  }
  //}

    &:hover {
      //.find-lock-image {
      //    width: calc(100% - #{$selectedPadding}) !important;
      //    height: calc(100% - #{$selectedPadding}) !important;
      //}
    }
  }

  + .find-lock-answer {
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(md) {
    height: 30vh;
    max-width: 35vw;

    + .find-lock-answer {
      margin-top: 0;
      margin-left: 1.5vw;
    }
  }

  .find-lock-label-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    margin-bottom: 0;

    &:hover {
      cursor: pointer;

      .find-lock-label {
        &:after {
          height: 60%;
        }
      }

      .find-lock-category {
        font-size: 16px;
        font-size: 6vw;
        bottom: 40px;

        @include media-breakpoint-up(md) {
          font-size: 19px;
          font-size: 1.5vw;
          bottom: 55px;
        }
      }

      .find-lock-category-description {
        font-size: 14px;
        font-size: 3.6vw;
        bottom: 20px;

        @include media-breakpoint-up(md) {
          font-size: 15px;
          font-size: 1.1vw;
          bottom: 30px;
        }
      }

      + .find-lock-image-container .find-lock-image {
        width: 102%;
        height: 102%;
      }
    }

    &:after,
    &:before {
      display: none;
    }
  }

  .find-lock-label {
    display: block;
    //position: absolute;
    //bottom: 10px;
    width: 100%;
    text-align: center;
    transition: all 250ms ease-out;
    color: #fff;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 50%;
      left: 0;
      bottom: 0;
      z-index: 100;
      transition: all 250ms ease-out;
      opacity: .8;
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    }

    //@include media-breakpoint-up(md) {
    //  bottom: 20px;
    //}
  }

  .find-lock-image-container {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .find-lock-image {
    //position: absolute;
    object-fit: cover;
    object-position: center right;
    transition: all 250ms ease-out;
    width: 100%;
    height: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .find-lock-category,
  .find-lock-category-description {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 200;
  }

  .find-lock-category {
    display: block;
    text-transform: uppercase;
    font-size: 16px;
    font-size: 5vw;
    font-weight: 700;
    font-family: $headings-font-family;
    transition: all 250ms ease-out;
    padding: 0 5px;
    word-break: break-word;
    line-height: 1.2;
    bottom: 35px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      font-size: 1.4vw;
      bottom: 15%;
    }
  }

  .find-lock-category-description {
    display: block;
    font-size: 14px;
    font-size: 3.6vw;
    font-family: $font-family-sans-serif;
    transition: all 250ms ease-out;
    padding: 0 5px;
    word-break: break-word;
    line-height: 1.2;
    bottom: 14px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
      font-size: 1vw;
      bottom: 8%;
    }
  }
}

#find-lock-questions {
  @include media-breakpoint-up(md) {
    max-height: 37vh;
    height: 37vh;
  }
}

.find-lock-question {
  width: 100vw;
  height: calc(100vh - 320px);
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  transition: opacity 400ms ease-out;
  z-index: 0;
  opacity: 0;
  position: absolute;
  left: 0;
  padding: 0 4vw;

  &.active {
    z-index: 500;
    opacity: 1;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    text-transform: inherit;
    margin-bottom: 25px;
    flex: 0 0 auto;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  @include media-breakpoint-up(md) {
    width: 96vw;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
  }
}

.find-lock-nav {
  padding: 10px 10px 5px;
  display: flex;
  flex-grow: 1;
  flex-flow: column-reverse nowrap;

  @include media-breakpoint-up(md) {
    margin-top: 20px;
    padding-bottom: 20px;
    flex-flow: column nowrap;
  }

  .btn-back {
    margin-left: -10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 400ms ease-out;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .fas {
      color: $dark;
    }

    @include media-breakpoint-up(md) {
      margin-left: -70px;
    }
  }

  .find-lock-progress {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    opacity: 0;
    transition: opacity 400ms ease-out;

    &.active {
      opacity: 1;
    }

    .progress-item {
      width: 13vw;
      height: 3px;
      background-color: $lightGray;
      transition-duration: 400ms;
      transition-padding: color, height;
      transition-timing-function: ease-out;

      + .progress-item {
        margin-left: 15px;
      }

      @include media-breakpoint-up(md) {
        width: 6vw;

        + .progress-item {
          margin-left: 9px;
        }
      }
    }

    .progress-item.complete {
      height: 8px;
      background-color: $primaryAlt;
    }
  }
}

.find-lock-actions {
  text-align: center;
  padding: 10px 0;

  #btnSearch {
    opacity: 0;
    transition: all 0.15s ease;

    &.active {
      opacity: 1;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 30px 0 0;
  }
}

.floating-flag {
  top: 0;
  left: 0;
  width: 193px;
  @include media-breakpoint-up(md) {
    top: 20px;
  }
  .featured-button {
    box-shadow: 4px 4px 8px 0 rgba(0,0,0,0.15);
    padding-left: 15px;
  }
}

.find-lock-results-heading {
  font-weight: 700;
  @include media-breakpoint-up(lg) {
      font-size: $h3-font-size;
      font-weight: 800;
  }
}

.product-card-list-price {
  @include media-breakpoint-down(lg) {
    max-width: 120px;
  }

}