.frame-border-top {
    height: 15px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    @include media-breakpoint-up(lg) {
        z-index: 1000;
    }
}

.frame-border-bottom {
    height: 15px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    @include media-breakpoint-up(lg) {
        z-index: 1000;
    }
}

.frame-border-left {
    width: 15px;
    background-color: #fff;
    position: fixed;
    top: -100px;
    left: 0;
    bottom: -100px;
    z-index: 2000;
    @include media-breakpoint-up(lg) {
        z-index: 1000;
    }
}

.frame-border-right {
    width: 15px;
    background-color: #fff;
    position: fixed;
    top: -100px;
    bottom: -100px;
    right: 0;
    z-index: 2000;
    @include media-breakpoint-up(lg) {
        z-index: 1000;
    }
}