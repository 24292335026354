
#cookie-notification {
	position: fixed;
	bottom: -400px;
	left: 0;
    z-index: 1002; //above find a lock
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 20px 0;
    color: #000;
	font-size: 12px;
    transition: bottom .5s linear;
    font-size: .9rem;
	-webkit-box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.38);
	-moz-box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.38);
	box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.38);
	@media only screen and (min-width: 767px) {
		bottom: -200px;
	}
}

#cookie-notification .row {
    min-height: 30px;
}

#cookie-notification p {	
	margin: 0 25px 20px;
    line-height: normal;
	@media only screen and (min-width: 767px) {
		margin: 0 200px 0 50px;
	}	
}

#cookie-notification-close {
	display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
	color: #FFF;

	@media only screen and (min-width: 767px) {
		display: block;
		position: absolute;
		top: 5px;
		right: 0;
		margin: 0;
		width: auto;
	}
}